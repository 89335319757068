<template>
    <div class="about-wrap style1 ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="about-img-wrap">
                        <img src="../../assets/images/about/about-shape-1.png" alt="Image" class="about-shape-one bounce">
                        <img src="../../assets/images/about/about-shape-2.png" alt="Image"
                            class="about-shape-two moveHorizontal">
                        <img src="../../assets/images/about/about-img-1.png" alt="Image" class="about-img">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="about-content">
                        <img src="../../assets/images/about/about-shape-3.png" alt="Image" class="about-shape-three">
                        <div class="content-title style1">
                            <span>Smart Banking</span>
                            <h2>Netwallet: The Better Way To Save &amp; Invest</h2>
                            <p>Netwallet is a very secure online savings platform that makes saving possible by combining
                                discipline plus flexibility to make you grow your savings.</p>
                        </div>
                        <ul class="content-feature-list style1 list-style">
                            <li><span><i class="flaticon-tick"></i></span>Account that work all across the world.</li>
                            <li><span><i class="flaticon-tick"></i></span>Low fees on your transaction.</li>
                            <li><span><i class="flaticon-tick"></i></span>No withdrawal fees. No minimum balance.</li>
                        </ul>
                        <router-link to="/about" class="btn style1">More About us</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmartBanking'
}
</script>