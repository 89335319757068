<template>
  <div>
    <div class="hero-wrap style3 bg-squeeze">
      <img src="../../assets/images/hero/hero-shape-10.png" alt="Image" class="hero-shape-one" />
      <img src="../../assets/images/hero/hero-shape-15.png" alt="Image" class="hero-shape-two" />
      <img src="../../assets/images/hero/hero-shape-14.png" alt="Image" class="hero-shape-three" />
      <img src="../../assets/images/hero/hero-shape-11.png" alt="Image" class="hero-shape-four animationFramesTwo" />
      <div class="hero-slider-one">
        <carousel>
          <Slide v-for="slide in carouselItems" :key="slide.id">
            <div class="hero-slide-item">
              <div class="container">
                <div class="row gx-5 align-items-center">
                  <div class="col-md-6">
                    <div class="hero-content" data-speed="0.10" data-revert="true">
                      <span>{{ slide.subtitle }}</span>
                      <h1>{{ slide.heading }}</h1>
                      <p>{{ slide.desc }}</p>
                      <div class="hero-btn">
                        <router-link to="/register" class="btn style1">Get Started</router-link>
                        <!-- <div
                          class="play-video"
                          v-on:click="isPopupMethod(isPopup)"
                          style="cursor: pointer"
                        >
                          <span class="play-btn">
                            <i class="flaticon-play-button-arrowhead"></i>
                          </span>
                          <span class="sm-none">Watch Video</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="hero-img-wrap">
                      <img src="../../assets/images/hero/hero-shape-13.png" alt="Image" class="hero-shape-five bounce" />
                      <img src="../../assets/images/hero/hero-shape-12.png" alt="Image"
                        class="hero-shape-six moveHorizontal" />
                      <img :src="slide.image" alt="Image" class="hero-img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </div>

    <div class="popup-video" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data: () => ({
    isPopup: false,
    carouselItems: [
      {
        id: 1,
        subtitle: "Instant Money Transfer",
        heading: "Transfer Money Across The World In Real Time",
        desc: "Fast, flexible and secure international money transfers around the world. Transfer funds globally any time, anywhere with zero transfer fees and real-time foreign exchange rates. Save time and money when you send money internationally with us.",
        image: require("../../assets/images/hero/hero-slide-1.png"),
      },
      {
        id: 2,
        subtitle: "Online Payment System",
        heading: "Secured & Easy Online Payment Solution",
        desc: "Online payment methods are platforms that allow you to collect money from clients for their purchases online rather than in person or by phone. Wherever you are in the world, NetWallet gives you the choice and control to securely manage all of your payment, carry out your everyday transactions and more anytime.",
        image: require("../../assets/images/hero/hero-slide.jpg"),
      },
      {
        id: 3,
        subtitle: "Direct payment with Mobile money and QR code",
        heading: "Scan To Pay, From Mobile Money To QR Pay",
        desc: "NetWallet provides a fully integrated system for users to make payment and transfer money in a very simple and convenient way. Combining mobile money and QR code payment, NetWallet provides direct payment method in shops and malls.",
        image: require("../../assets/images/hero/hero-slide1.jpg"),
      },
    ],
  }),
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
});
</script>
