<template>
    <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link to="/service-details">Cash Collection</router-link></h3>
                            </div>
                            <p>The impact of mobile money can be seen in the financial inclusion statistics of the continent. Mobile Money penetration is over 80 per cent. Even in rural areas, it has become the currency of choice.</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link to="/service-details">International Settlement</router-link></h3>
                            </div>
                            <p>
                                Mobile money has established itself as a critical tool for facilitating international remittances, reducing costs and maximising the impact of remittances on development.
                            </p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link to="/service-details">Bill Payment</router-link></h3>
                            </div>
                            <p>Effortlessly manage transactions (money-in and out) and craft your ideal transaction flow. Automate all your payments and transactions, reduce administrative works and save resources.</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-payment-method"></i></span>
                                <h3><router-link to="/service-details">Fraud Detection</router-link></h3>
                            </div>
                            <p>We return powerful insights and real-time data enrichment for all fraud detection needs. Build trust throughout the user journey. Stop fake accounts, payment fraud</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-computer"></i></span>
                                <h3><router-link to="/service-details">Account Updater</router-link></h3>
                            </div>
                            <p>Automatically requests for updates to your Netwallet account information as the payment is being processed. Batch Account Updater.</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-loan-1"></i></span>
                                <h3><router-link to="/service-details">Secured Transaction</router-link></h3>
                            </div>
                            <p>Transaction security is the most fundamental form of security checking that is required to secure any payment or any cash collection.</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-secure-shield"></i></span>
                                <h3><router-link to="/service-details">Secure Payment</router-link></h3>
                            </div>
                            <p>Make payment in a safe, fast, and secure way to your recipient within Netwallet and to popular payment systems in Africa.</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-mortarboard"></i></span>
                                <h3><router-link to="/service-details">Diapora and Families</router-link></h3>
                            </div>
                            <p>Connecting families​​ Mobile wallets allow people to send money to family, friends, and small businesses using mobile phones</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="service-card style4">
                        <div class="service-info">
                            <div class="service-title">
                                <span><i class="flaticon-loan"></i></span>
                                <h3><router-link to="/service-details">Email Notification</router-link></h3>
                            </div>
                            <p>To keep you informed about the activity in your payments profile, Google sends email notifications for certain payments events.</p>
                            <!--<router-link to="/service-details" class="link style1">Learn More <i class="flaticon-right-arrow-1"></i></router-link>-->
                        </div>
                    </div>
                </div>
            </div>
            <!--<ul class="page-nav list-style mt-20">
                <li><a href="/services-two"><i class="flaticon-back"></i></a></li>
                <li><a class="active" href="/services-two">1</a></li>
                <li><a href="/services-two">2</a></li>
                <li><a href="/services-two">3</a></li>
                <li><a href="/services-two"><i class="flaticon-next-1"></i></a></li>
            </ul>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>