<template>
    <div class="Login-wrap ptb-100">
        <div class="container">
            <div class="row ">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                    <div class="login-form-wrap">
                        <div class="login-header">
                            <h3>Register New Account</h3>
                            <p>Open Netwalletpay account</p>
                        </div>
                        <div class="login-form">
                            <div class="login-body">
                                <form class="form-wrap" @submit.prevent="registerUser">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input id="text" name="fname" type="text" placeholder="First Name" required
                                                    v-model="firstName">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input id="text" name="lname" type="text" placeholder="Last Name" required
                                                    v-model="lastName">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input id="email" name="email" type="email" placeholder="Email" required
                                                    v-model="email">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input id="phone" name="phone" placeholder="Phone number" required
                                                    v-model="mobile">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <input id="bank" name="bank" placeholder="Bank account" required
                                                    v-model="bankAccount">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <select id="country" name="country" class="form-control"
                                                    placeholder="country" required v-model="country">
                                                    <option value="CM" selected>Cameroon</option>
                                                    <option value="UG">Uganda</option>
                                                    <option value="KE">Kenya</option>
                                                    <option value="TZ">Tanzania</option>
                                                    <option value="RW">Rwanda</option>
                                                    <option value="BI">Burundi</option>
                                                    <option value="GH">Ghana</option>
                                                    <option value="ZM">Zambia</option>
                                                    <option value="GQ">Equatorial Guinea</option>
                                                    <option value="MY">Malaysia</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-12 mb-20">
                                            <div class="checkbox style3">
                                                <input type="checkbox" id="test_1">
                                                <label for="test_1">
                                                    I Agree with the
                                                    <router-link class="link style1" to="/terms-of-service">Terms &amp;
                                                        conditions</router-link>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <button class="btn style1">
                                                    Register Now
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <p class="mb-0">
                                                Have an Account?
                                                <router-link class="link style1" to="/login">Sign In</router-link>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Register',
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            country: 'CM',
            bankAccount: '',
            error: null,
        };
    },
    methods: {
        async registerUser() {
            try {
                const response = await axios.post('/Account/Register', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    mobile: this.mobile,
                    country: this.country,
                    bankAccount: this.bankAccount,
                });
                this.error = null;
                this.$router.push('/login');
            } catch (error) {
                console.error(error);
                const { message } = error.response.data;

                // Handle the login error
                this.error = message || 'Unexpected error, please try again later';
            }
        },
    },
}
</script>