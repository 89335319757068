<template>
    <div class="goal-wrap ptb-100">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="goal-img-wrap">
                        <img src="../../assets/images/goal/goal-shape-1.png" alt="Image" class="goal-shape-three bounce">
                        <img src="../../assets/images/goal/goal-shape-2.png" alt="Image" class="goal-shape-one">
                        <img src="../../assets/images/goal/goal-shape-3.png" alt="Image" class="goal-shape-two">
                        <img src="../../assets/images/goal/goal-2.jpg" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="goal-content">
                        <div class="content-title style1">
                            <span>Goal Setting</span>
                            <h2>Manage Your Money With Your Mobile Wallet </h2>
                            <p><strong>NetWallet</strong> gives direct access to your account to carry out financial transactions through the internet on your smartphone, tablet or computer. It's quick, usually free and allows you to do tasks, such as paying bills and transferring money, without having to visit or call your bank.</p>
                        </div>
                        <ul class="content-feature-list style1 list-style">
                            <li><span><i class="flaticon-tick"></i></span>Learn To Budget</li>
                            <li><span><i class="flaticon-tick"></i></span>Create An Emergency Fund</li>
                            <li><span><i class="flaticon-tick"></i></span>Get Out Of Debt</li>
                            <li><span><i class="flaticon-tick"></i></span>Save And Invest Your Money</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GoalSettings'
}
</script>